import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave from "src/components/nonbmaWave"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_carbonate from "src/components/btf_carbonate"
import Carbonate_disc_search from "src/components/carbonate_disc_search"


const wavelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`

body {
	font-family: 'Roboto', sans-serif;
}
       .footerlinks-module--br {
         display: none!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         padding: 16px 0 16px 40px;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

       #ctadisclosurecb3-module--ctadisclaimer {
         display: none;
       }
       .wave-module--wave h1 {
         font-size: 22px;
       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Fast, Easy and Safe Web Browsing - Carbonate</title></Helmet>
    <section>
      <NonbmaWave data={wavelpData}><Carbonate_disc_search></Carbonate_disc_search></NonbmaWave>
      <Btf_carbonate data={btfData}></Btf_carbonate>
      </section>
    </HomepageLayout>
  )
}
